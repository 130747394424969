import React from "react"
import { isMobileOnly, isTablet } from "react-device-detect"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import {
  shuffleArray,
  getUnrelatedReviews,
  getHearFromHeadingAndBlurbObject,
  getPostObj,
  isFacewallTextGeneric
} from "../utils/utils"
import SEO from "../components/seo"

import Button from "@components/Button/Button"
import MarkdownViewer from "@components/MarkdownViewer"
import HeadingBlurbMedia from "../components/BodyElements/HeadingBlurbMedia"
import MarkdownSection from "../components/BodyElements/MarkdownSection"
import FeaturedList from "../components/BodyElements/FeaturedList"
import GenericSection from "../components/BodyElements/GenericSection"
import ImageText from "../components/BodyElements/ImageText"
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import FixedFacewall from "../components/carousel/FixedFacewall"
import ReviewRowsSection from "../components/ReviewRowsSection"
import AnesthesiaModal from "../components/Modals/AnesthesiaModal"
import DropdownButton from "../components/DropdownButton"
import ImageStrip from "../components/ImageStrip/ImageStrip"
import { TestimonialPreview } from "../components/Cards"
import TestimonialGrid from "../components/Cards/TestimonialGrid"

import { graphql } from "gatsby"
import SingleProcedureHero from "../components/SingleProcedureHero"

var classNames = require("classnames")

var showdown = require("showdown")

const SingleProcedureAnimated = ({ data, pageContext, location }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allProcedures.nodes[0],
    data.allProceduresEs.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  // SDOS classNames
  const HeadingBlurbMediaDI =
    post.name === "Dental Implants" ? "Dental Implants" : ""

  let getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  let getStartedDataEs = data.allTemplateSettingsJson.nodes[0].getStartedSpanish

  // let procedureGetStartedData = data.allProcedures.nodes[0].getStarted

  // // S DOS Unique Procedure Get Started Buttons
  // try {
  //   if (language === "en" && data.allProcedures && data.allProcedures.nodes.length > 0 && data.allProcedures.nodes[0].getStarted) {
  //     getStartedData = data.allProcedures.nodes[0].getStarted
  //   } else if (language === "es" && data.allProceduresEs && data.allProceduresEs.nodes.length > 0 && data.allProceduresEs.nodes[0].getStarted) {
  //     getStartedDataEs = data.allProceduresEs.nodes[0].getStarted
  //   }
  // } catch (err) {
  //   console.error(err)
  // }

  var relatedReviews = []
  var allReviews = []

  if (data.relatedReviews.nodes) relatedReviews = data.relatedReviews.nodes
  if (data.allReviews.nodes) allReviews = data.allReviews.nodes

  shuffleArray(relatedReviews)

  const originalReviewCount = relatedReviews.length

  var unrelatedReviews = getUnrelatedReviews(
    relatedReviews,
    allReviews,
    "title"
  )

  shuffleArray(unrelatedReviews)

  var fixedFacewallReviews = []
  var reviewRowsReviews = []

  // Limit leftover reviews to 5, all reviews per page to 9
  var relatedReviewsLength = relatedReviews.length
  if (relatedReviewsLength > 4 + 5) {
    relatedReviewsLength = 9
  }

  // If longer than 4, Split reviews into facewall (4) and review rows (up to 5)
  if (originalReviewCount > 4) {
    fixedFacewallReviews = relatedReviews.slice(0, 4)
    reviewRowsReviews = relatedReviews.slice(4, relatedReviewsLength)
  }

  // Else, fill facewall with unrelated reviews until 4 long
  else if (originalReviewCount < 4) {
    fixedFacewallReviews = relatedReviews
    while (fixedFacewallReviews.length < 4) {
      fixedFacewallReviews.push(unrelatedReviews.pop())
    }
  } else {
    fixedFacewallReviews = relatedReviews
  }

  const rvs = relatedReviews ? relatedReviews : data.allReviews.nodes
  let wallReviews = rvs
  // match this with Cards.scss if amount changes
  if (isMobileOnly) {
    wallReviews = rvs.splice(0, 6)
  } else if (isTablet) {
    wallReviews = rvs.splice(0, 30)
  } else {
    wallReviews = rvs
  }

  const converter = new showdown.Converter()
  var cta = post.ctaSection

  var ctaHtml = converter.makeHtml(cta)

  function createCtaHtml() {
    return { __html: ctaHtml }
  }

  function createHtml(html) {
    return { __html: html }
  }

  function BodySection(props) {
    if (props.type === "textSection") {
      return (
        <MarkdownSection
          procedureName={post.name}
          hasDropdownButton={props.hasDropdownButton}
          colorBack={props.colorBack}
          textSection={props.textSection}
          buttons={props.buttons}
          sideColumnIs={props.sideColumnIs}
          paddingBottom={props.paddingBottom ? props.paddingBottom : null}
          paddingTop={props.paddingTop ? props.paddingTop : null}>
          {post.dropdownButton.hasThisSection && (
            <DropdownButton
              isCenteredMobile
              heading={
                !post.dropdownButton.heading
                  ? "Other Locations"
                  : post.dropdownButton.heading
              }
              links={post.dropdownButton.links}
            />
          )}
        </MarkdownSection>
      )
    } else if (props.type === "headingBlurbMedia") {
      return <HeadingBlurbMedia {...props} customStyle={HeadingBlurbMediaDI} />
    } else if (props.type === "featuredList") {
      return (
        <FeaturedList
          listContent={props}
          headingSideColumnIs={5}
          listSideColumnIs={5}
        />
      )
    } else if (props.type === "fixedFacewall") {
      if (fixedFacewallReviews.length >= 4 && language !== "es") {
        return (
          <FixedFacewall
            blurb={<p>{fixedFacewallBlurb}</p>}
            heading={fixedFacewallHeading}
            sideColumnIs={1}
            middleColumnsis={1}
            reviews={fixedFacewallReviews}
          />
        )
      }
    } else if (props.type === "anesthesiaModal") {
      return (
        <div className={`single-procedure-anesthesia`}>
          <AnesthesiaModal bodySections procedurePage />
        </div>
      )
    } else if (props.type === "imageStripSection") {
      return <ImageStrip images={props.imageStrip} />
    } else if (props.type === "genericSection") {
      return <GenericSection {...props} />
    } else if (props.type === "imageText") {
      return (
        <ImageText
          sideColumnIs={props.sideColumnIs}
          textSection={props.textSection}
          extraText={props.extraText}
          imageId={props.imageId}
          heading={props.heading}
          imageTextReverse={props.imageTextReverse}
        />
      )
    } else {
      return <></>
    }
  }

  let bodySections

  bodySections = post.bodySections.map((section, i) => {
    return (
      <React.Fragment key={i}>
        <BodySection {...section} />
      </React.Fragment>
    )
  })

  var mobileMainPhotoPublicId = post.mainPhotoMobilePublicId

  var headingAndBlurb = getHearFromHeadingAndBlurbObject(
    post,
    originalReviewCount
  )

  var fixedFacewallHeading = headingAndBlurb.heading
  var fixedFacewallBlurb = headingAndBlurb.blurb

  var procedureCopy

  // A Tale of Two Templates
  if (!post.youtube && !post.mainPhotoPublicId) {
    procedureCopy = (
      <section className="procedure procedure-body no-video top-section section white-back joshua-tree-content">
        {bodySections}
      </section>
    )
  } else {
    procedureCopy = (
      <div
        className={
          language === "es"
            ? "procedure-body procedure-body-es"
            : "procedure-body"
        }>
        {bodySections}
      </div>
    )
  }

  var backUrl = "/" + data.allUniquePagesJson.nodes[0].title

  let facewallClasses = classNames("procedure-facewall", {
    generic: isFacewallTextGeneric(post, originalReviewCount)
  })

  function SingleProcedureAnesthesia({ colorBack, useModalButtons2 }) {
    let spaClasses = classNames("single-procedure-anesthesia", {
      "no-color-back": !colorBack
    })
    return (
      <div className={spaClasses}>
        <AnesthesiaModal useModalButtons2={useModalButtons2} procedurePage />
      </div>
    )
  }
  return (
    <SharedStateProvider>
      <Layout
        pageTitle={
          post.title != "cosmetic-surgery/" ? "procedure-page" : post.title
        }
        language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
          keywords={post.metaKeywords}
          youtubeSchemas={pageContext.youtubeSchemasJson}
          preload={[
            {
              as: "image",
              href: `https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99,w_400/v1/${mobileMainPhotoPublicId}.jpg`
            }
          ]}
        />

        <SingleProcedureHero
          settings={post.hero}
          animationStyle={post?.animationStart}
          iconPosition={post.hero?.position}
          heading={post.heading}
          image={post.heroIcon}
          imageMobile={post.heroIconMobile}
          language={language}
          youtube={post.youtube}
          dropdownButton={post.dropdownButton}
          goBackUrl={language === "es" ? "/es/procedimientos/" : backUrl}
        />

        {language == "en" && (
          <TestimonialGrid language={language} reviews={wallReviews} />
        )}

        {post.dropdownButton.hasThisSection && (
          <div
            className="is-hidden-desktop body-sections"
            style={{ maxWidth: "200px", marginInline: "auto", padding: 0 }}>
            <DropdownButton
              isCentered
              heading="Other Locations"
              links={post.dropdownButton.links}
            />
          </div>
        )}

        <div>{procedureCopy}</div>

        {post.procedureLevel === "featured" && language !== "es" && (
          <SingleProcedureAnesthesia
            useModalButtons2={
              post.useModalButtons2 ? post.useModalButtons2 : false
            }
            colorBack={
              post.bodySections[post.bodySections.length - 1].type !==
              "featuredList"
            }
          />
        )}

        {/* {fixedFacewallReviews.length >= 4 && language !== "es" && (
          <div className={facewallClasses}>
            <FixedFacewall
              blurb={fixedFacewallBlurb}
              heading={fixedFacewallHeading}
              sideColumnIs={1}
              middleColumnsis={1}
              reviews={fixedFacewallReviews}
              isGeneric={isFacewallTextGeneric(post, originalReviewCount)}
              button
              hideProcedureNames={
                !isFacewallTextGeneric(post, originalReviewCount)
              }
            />
          </div>
        )} */}

        {post.ctaSection && (
          <section
            className="body-sections section joshua-tree-content cta-with-button"
            style={{ paddingTop: "0" }}>
            <div className="columns">
              <div className="column is-5"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createCtaHtml()}></div>
              <div className="column is-5"></div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <Button buttonText="Get Started" href="/get-started/" />
              </div>
            </div>
          </section>
        )}

        {reviewRowsReviews.length > 0 && language !== "es" && (
          <ReviewRowsSection
            reviews={reviewRowsReviews}
            nameSingular={post.nameSingular}
            //paddingTop={post.ctaSection ? "" : "0"}
            // paddingBottom={"0"}
            classnames="di-reviews"
          />
        )}

        {post.faqSection.hasThisSection && (
          <div className={`body-sections faq-section color-back`}>
            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <MarkdownViewer markdown={post.faqSection.text} />
              </div>
              <div className="column is-5"></div>
            </div>
          </div>
        )}

        <div>
          <OffsetHeadingCTA
            colorBack={post.ctaColorBack}
            className={`single-procedure-cta`}
            sideColumnIs={4}
            headingJSX={
              <h3 className="has-text-centered-tablet">
                {language === "es"
                  ? getStartedDataEs.heading
                  : getStartedData.heading}
              </h3>
            }
            paragraphJSX={
              <div
                className="has-text-centered-tablet"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    language === "es"
                      ? getStartedDataEs.blurb
                      : getStartedData.blurb
                  )
                )}></div>
            }
            buttonText={
              language === "es"
                ? getStartedDataEs.buttonText
                : getStartedData.buttonText
            }
            buttonUrl={
              language === "es" ? getStartedDataEs.href : getStartedData.href
            }
            buttons={
              language === "es"
                ? getStartedDataEs.buttons
                : getStartedData.buttons
            }
          />
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query ($title: String!, $procedureName: [String]) {
    allUniquePagesJson(filter: { template: { eq: "procedures" } }) {
      nodes {
        title
      }
    }
    allTemplateSettingsJson(
      filter: { templateType: { eq: "single-procedure" } }
    ) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    allProcedures: allProceduresJson(filter: { title: { eq: $title } }) {
      nodes {
        hero {
          position
          imageProportion
          iconContainerClass
        }
        title
        name
        heading
        subheading
        heroIcon
        animationStart
        heroIconMobile
        whiteTopHeadings
        goBackWhiteMobile
        nameSingular
        youtube
        procedureLevel
        dropdownButton {
          hasThisSection
          heading
          links {
            link {
              text
              title
            }
          }
        }
        bodySections {
          heading
          paragraph
          textSection
          imageTextReverse
          imageId
          extraText
          # buttons {
          #   button {
          #     buttonText
          #     href
          #     appearance
          #     destination
          #   }
          # }
          colorBack
          hasDropdownButton
          type
          youtube
          youtubePhotoPublicId
          videoSideColumnIs
          headingSideColumnIs
          sideColumnIs
          paddingBottom
          paddingTop
          headingLevel
          headingLook
          colorBack
          featuredListHeading
          featuredListItems {
            featuredListItem {
              featuredListItemBlurb
              featuredListItemHeading
            }
          }
          genericSection {
            type
            isCentered
            heading
            headingType
            textSection
            textAlign
            paddingBottom
            paddingTop
            buttons {
              button {
                buttonText
                href
                minimal
                contained
              }
            }
          }
        }
        faqSection {
          hasThisSection
          text
        }
        useModalButtons2
        ctaColorBack
        getStarted {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          blurb
          heading
        }
        metaTitle
        metaDescription
        schemaPage
        mainPhotoPublicId
        mainPhotoMobilePublicId
      }
    }
    allProceduresEs: allSpanishProceduresJson(
      filter: { title: { eq: $title } }
    ) {
      nodes {
        title
        name
        heading
        subheading
        whiteTopHeadings
        goBackWhiteMobile
        nameSingular
        youtube
        procedureLevel
        dropdownButton {
          hasThisSection
          heading
          links {
            link {
              text
              title
            }
          }
        }
        bodySections {
          heading
          paragraph
          textSection
          imageTextReverse
          imageId
          extraText
          # buttons {
          #   button {
          #     buttonText
          #     href
          #     appearance
          #     destination
          #   }
          # }
          type
          youtube
          youtubePhotoPublicId
          videoSideColumnIs
          headingSideColumnIs
          sideColumnIs
          paddingBottom
          paddingTop
          headingLevel
          headingLook
          colorBack
          # featuredListHeading
          # featuredListItems {
          #   featuredListItem {
          #     featuredListItemBlurb
          #     featuredListItemHeading
          #   }
          # }
          genericSection {
            type
            isCentered
            heading
            headingType
            textSection
            textAlign
            paddingBottom
            paddingTop
            buttons {
              button {
                buttonText
                href
                minimal
                contained
              }
            }
          }
        }
        faqSection {
          hasThisSection
          text
        }
        useModalButtons2
        ctaColorBack
        getStarted {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          blurb
          heading
        }
        metaTitle
        metaDescription
        schemaPage
        mainPhotoPublicId
        mainPhotoMobilePublicId
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: $procedureName } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
    anesthesiaJson: allUniquePagesJson(
      filter: { title: { eq: "anesthesia-options/" } }
    ) {
      nodes {
        anesthesiaTypes {
          blurb
          buttonOne {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
          buttonThree {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
          heading
          buttonTwo {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
        }
      }
    }
  }
`
// S DOS Customization
function GridRow(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-3"></div>}
            <div
              className={`column image-button-wrapper ${
                itemLen === i + 1 ? "is-8" : "is-8"
              }`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hover-link"
                href={item.href}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={item.image}
                  width="auto"
                  responsive></ImageMeta>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                  className="standard-button contained image-button">
                  Learn More
                </a>
              </a>
            </div>
            {i === 0 && <div className="column is-2"></div>}
            {i === 1 && <div className="column is-3"></div>}
          </>
        )
      })}
    </div>
  )
}

function Grid(props) {
  // Split into rows of 2
  var chunk_size = props.rowsOf
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRow key={`media-coverage-row-${i}`} items={row} />
  ))
}

export default SingleProcedureAnimated
